// Remove Focus Boxes
select:focus {
  outline: $select-focus;
}

form {
  .form_right {
    height: auto!important;
  }
}

button:focus {
  outline: none;
  background-color: $button-background-focus;
}

label {
  font-size: $label-font-size;
  color: #{$text-color};
  text-align: left!important;
  width: 100% !important;
  margin-bottom: 5px !important;
  &.noFloatingLabel {
    display: block;
    height: auto !important;
    padding: 10px 5px !important;
  }
}

form.generateur label {
  padding: 5px 5px 10px !important;
}

form.generateur p.submit {
  margin-left: 0 !important;
  padding: 0 !important;
}

.fancybox-inner {
  height: 550px !important;
}

.validation {
  display: none;
}

span.required {
  color: #{$error-color};
}

.divGenerateurRequired {
  margin-top: 1rem;
}

form.generateur label[for=field_15] {
  display: none;
}

// ==================== Date

#ui-datepicker-div {

  .ui-widget-header {
    border: 1px solid #{$secondary-color};
    background-color: #{$secondary-color};
  }

}

@import 'input-fields';
//@import 'radio-buttons';
//@import 'checkboxes';
@import 'switches';
@import 'select';
@import 'file-input';
@import 'range';