// Colors
// ==========================================================================

$primary-color: var(--primary-color);
$primary-color-lighten: var(--primary-color-lighten);
$primary-color-darken: var(--primary-color-darken);

$secondary-color: var(--secondary-color);
$secondary-color-lighten: var(--secondary-color-lighten);
$secondary-color-darken: var(--secondary-color-darken);

$accent-color: var(--accent-color);
$accent-color-lighten: var(--accent-color-lighten);
$accent-color-darken: var(--accent-color-darken);

$text-color: var(--text-color);
$text-color-lighten: var(--text-color-lighten);
$background-color: var(--background-color);

$primary-background-color: var(--primary-background-color);
$secondary-background-color: var(--secondary-background-color);
$accent-background-color: var(--accent-background-color);

$success-color: var(--success-color);
$error-color: var(--error-color);
$info-color: var(--info-color);


// Buttons
// ==========================================================================

$button-border: none !default;
$button-background-focus: #{$secondary-color-lighten} !default;
$button-font-size: 16px !default;
$button-icon-font-size: 1.25rem !default;
$button-height: 52px;
$button-line-height: $button-font-size * 1.5 !default;
$button-padding-top: ($button-height - $button-line-height) / 2 !default;
$button-padding-right: 20px !default;
$button-radius: var(--button-border-radius) !default;

// Media Query Ranges
// ==========================================================================

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

// Typography
// ==========================================================================

$font-stack: var(--font-primary);
$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 3rem !default;
$h2-fontsize: 2rem !default;
$h3-fontsize: 1.5rem !default;
$h4-fontsize: 1.25rem !default;
$h5-fontsize: 1.125rem !default;
$h6-fontsize: 1rem !default;
// Flow Text
$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

// 4. Cards
// ==========================================================================

$card-padding: 24px !default;
$card-bg-color: #FFFFFF !default;
$card-border-radius: var(--card-border-radius) !default;
$card-link-color: var(--accent-color) !default;
$card-link-color-light: var(--accent-color) !default;

// 5. Slider
// ==========================================================================

$img-slide-01: var(--image-slide-01);
$img-slide-02: var(--image-slide-02);
$img-slide-03: var(--image-slide-03);

// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 3rem !default;
$input-border-color: #C2C2C2;
$input-border: 1px solid $input-border-color !default;
$input-background: #{$background-color} !default;
$input-error-color: $error-color !default;
$input-success-color: #{$success-color} !default;
$input-focus-color: #{$secondary-color} !default;
$input-font-size: 16px !default;
$input-margin-bottom: .5rem;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 1rem !default;
$label-font-size: 1rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$input-icon-size: 2rem;
$placeholder-text-color: #aaaaaa !default;

// Radio Buttons
$radio-fill-color: #{$secondary-color} !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid #{$primary-color-lighten} !default;
$select-option-hover: rgba(0,0,0,.08) !default;
$select-option-focus: rgba(0,0,0,.08) !default;
$select-option-selected: rgba(0,0,0,.03) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0,0,0,.3) !default;

// Switches
$switch-bg-color: #{$secondary-color} !default;
$switch-checked-lever-bg: #{$secondary-color} !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: rgba(0,0,0,.38) !default;
$switch-radius: 15px !default;

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: #{$secondary-color} !default;
$dropdown-item-height: 50px !default;