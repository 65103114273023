@charset "UTF-8";

// Variables;
@import "components/variables";

// Normalize
@import "components/normalize";

// Components
@import "components/color-classe";
@import "components/grid";
@import "components/typography";
@import "components/icons-material-design";
@import "components/top-bar";
@import "components/reseaux-sociaux";
@import "components/cards";
@import "components/global";
@import "components/buttons";
@import "components/flex-grid";
@import "components/avis";
@import "components/slider";
@import "components/forms/forms";
@import "components/encart-actualites";
@import "components/axecontenu";
@import "components/404";